//import "@/styles/globals.css";
import "../lib/date-extension";
import "../lib/yup-extension";
import type { AppProps } from "next/app";
import type { NextPage } from "next";
import { ReactElement, ReactNode, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "../styles/globals.css";
import { RecoilEnv, RecoilRoot } from "recoil";
import useMock from "@/hooks/use-mock";
import Fonts from "@/features/ui/fonts";
import "../features/line-sheet-sets/app-date-picker.css";
import "../scripts/wdyr";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "../features/landing/landing-superset.scss";
import "../i18n";
import "../sentry";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Inter } from "next/font/google";
import * as amplitude from "@amplitude/analytics-browser";

const inter = Inter({ subsets: ["latin"] });

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const sizes = {
  container: {
    "2xl": "1920px",
  },
};

const fonts = {
  heading: `'Noto Sans CJK KR', sans-serif`,
  body: `'Noto Sans CJK KR', sans-serif`,
};

const textStyles = {
  "heading-3xl": {
    fontSize: "60px",
    fontWeight: "bold",
    lineHeight: "80px",
    letterSpacing: 0,
  },
  "heading-2xl": {
    fontSize: "48px",
    fontWeight: "bold",
    lineHeight: "68px",
    letterSpacing: 0,
  },
  "heading-xl": {
    fontSize: "36px",
    fontWeight: "bold",
    lineHeight: "48px",
    letterSpacing: 0,
  },
  "heading-lg": {
    fontSize: "28px",
    fontWeight: "bold",
    lineHeight: "40px",
    letterSpacing: 0,
  },
  "heading-md": {
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: "36px",
    letterSpacing: 0,
  },
  "heading-sm": {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "28px",
    letterSpacing: 0,
  },
  "body-lg-bold": {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "body-lg-semi-bold": {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "body-lg-medium": {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "body-lg-regular": {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "body-md-bold": {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "body-md-semi-bold": {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "body-md-medium": {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "body-md-regular": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "body-sm-bold": {
    fontSize: "13px",
    fontWeight: "bold",
    lineHeight: "20px",
    letterSpacing: 0,
  },
  "body-sm-medium": {
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: 0,
  },
  "body-sm-regular": {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: 0,
  },
  "caption-bold": {
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "16px",
    letterSpacing: 0,
  },
  "caption-medium": {
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: 0,
  },
  "caption-regular": {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: 0,
  },
  "body-md-underline": {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "24px",
    letterSpacing: 0,
  },
  "body-sm-underline": {
    fontSize: "13px",
    fontWeight: "bold",
    lineHeight: "20px",
    letterSpacing: 0,
  },
  "caption-underline": {
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "16px",
    letterSpacing: 0,
  },
};

const theme = extendTheme({
  sizes,
  fonts,
  textStyles,
  styles: {
    global: {
      body: {
        bg: "#FCFCFE",
      },
    },
  },
});

let resetMeta = false;

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const { i18n } = useTranslation();
  const { t } = useI18nHelper();
  const [shouldRender] = useMock();
  const [render, setRender] = useState(false);

  // link를 공유했을 때 미리보기에서 meta 정보가 보이게하려면(사이트 제목, 설명, 이미지를 크롤링할 수 있게 만들어주려면) _document.tsx에서 미리 meta를 넣어두어야 함.
  // _document.tsx에 넣어두어야 Next.js가 index.html 을 만들 때 meta를 같이 넣어주고, 그러면 공유한 서비스에서 단순 크롤링 시 index.html의 meta 정보를 가져갈 수 있음.
  // 그러나 HelmetProvider 안에 들어가지 않은 meta는 Helmet이 관리해주지 않음.
  // 따라서, 좋은 방법은 아니지만 _document.tsx 에서 설정한 meta를 지우고 Helmet의 관리하에 다시 넣어주는 방식으로 해결.
  // SSR을 하면 이 방식은 필요하지 않을 것 같음.
  const canUseDOM = typeof window !== "undefined";
  if (canUseDOM && !resetMeta) {
    const head = window.document.getElementsByTagName("head");
    if (head) {
      head[0]?.querySelector("[name='viewport']")?.remove();
      head[0]?.querySelector("[name='description']")?.remove();
      head[0]?.querySelector("[rel='canonical']")?.remove();
      head[0]?.querySelector("[property='og:locale']")?.remove();
      head[0]?.querySelector("[property='og:title']")?.remove();
      head[0]?.querySelector("[property='og:description']")?.remove();
      head[0]?.querySelector("[property='og:url']")?.remove();
      head[0]?.querySelector("[property='og:image']")?.remove();
      head[0]?.querySelector("[property='og:image:secure_url']")?.remove();
      head[0]?.querySelector("[property='og:image:type']")?.remove();
      head[0]?.querySelector("[property='og:image:width']")?.remove();
      head[0]?.querySelector("[property='og:image:height']")?.remove();
      head[0]?.querySelector("[property='og:image:alt']")?.remove();
      head[0]?.querySelector("[property='twitter:title']")?.remove();
      head[0]?.querySelector("[property='twitter:description']")?.remove();
      head[0]?.querySelector("[property='twitter:url']")?.remove();
      head[0]?.querySelector("[property='twitter:image']")?.remove();
      resetMeta = true;
    }
  }

  useEffect(() => {
    console.log(process.env.NODE_ENV);
    console.log(process.env.NODE_ENV === "development", "env");
    console.log(
      window.location.origin === "https://dev.pathrade.com.ngrok.app",
      "origin"
    );

    if (
      process.env.NODE_ENV === "production" &&
      window.location.origin === "https://dev.pathrade.com.ngrok.app"
    ) {
      console.log("hi");
      amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY!);
    }
  }, []);

  useEffect(() => {
    setRender(shouldRender);
  }, [shouldRender]);

  if (!render) {
    return null;
  }

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <RecoilRoot>
        <HelmetProvider>
          <Helmet>
            <title>{t("common:meta.site_name")}</title>
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1"
            />
            <meta name="description" content={t("common:meta.description")} />
            <link
              rel="canonical"
              href={window.location.origin + window.location.pathname}
            />
            <link rel="alternate" href={window.location.origin} hrefLang="en" />
            <link
              rel="alternate"
              href={`${window.location.origin}/ko`}
              hrefLang="ko"
            />
            <meta
              property="og:site_name"
              content={t("common:meta.site_name")}
            />
            <meta property="og:locale" content={i18n.language} />
            <meta property="og:title" content={t("common:meta.title")} />
            <meta
              property="og:description"
              content={t("common:meta.description")}
            />
            <meta
              property="og:image"
              content={window.location.origin + "/seo-image.png"}
            />
            <meta
              property="og:image:secure_url"
              content={window.location.origin + "/seo-image.png"}
            />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta
              property="og:image:alt"
              content={t("common:meta.description")}
            />
            <meta property="twitter:title" content={t("common:meta.title")} />
            <meta
              property="twitter:description"
              content={t("common:meta.description")}
            />
            <meta
              property="twitter:image"
              content={window.location.origin + "/seo-image.png"}
            />
          </Helmet>
          <ChakraProvider theme={theme}>
            <Fonts />
            <main className={inter.className}>
              {getLayout(<Component {...pageProps} />)}
            </main>
          </ChakraProvider>
        </HelmetProvider>
      </RecoilRoot>
    </Suspense>
  );
}
